import { post, put } from './helpers'

export default {
  createSubscriptionItem(data) {
    return post(`api/subscription_items`, { data })
  },

  updateSubscriptionItem(params) {
    return put(`api/subscription_items/${params.subscription_item_id}`, {
      data: { quantity: params.quantity, type: params.type }
    })
  }
}
