import * as Cookies from 'services/cookies'
import { extractLimitHeaders } from 'services/api/helpers'
import { SET_FEATURE_LIMIT_EVENT } from 'constants/account/permissions'

const SPHINX_LOCALHOST = 'http://localhost:5050'
const SPHINX_ENDPOINTS = {
  // WIP: staging lambda endpoints
  staging: {},
  // Development: Use this env for testing deployed development lambda locally (uses prod token), local Sphinx doesn't always behave the same as deployed lambda
  development: {
    streaming:
      'https://esh25rg5kd23nmwutybeuo4vba0buzwo.lambda-url.us-west-2.on.aws',
    http: 'https://t6xzi76xcrophswajepif7xibq0msnyq.lambda-url.us-west-2.on.aws'
  },
  production: {
    streaming:
      'https://3pqjs4immknkijrtkeqotzrxnu0irvrc.lambda-url.us-west-2.on.aws',
    http: 'https://j426iku7lgzj5h6ajlxrs5c2lm0qvdok.lambda-url.us-west-2.on.aws'
  }
}

/* Sphinx Endpoints */
export const getQuestionsEndpoint = () => ({ path: 'questions', method: 'GET' })

export const createQuestionEndpoint = body => ({
  path: 'questions',
  method: 'POST',
  body,
  type: 'streaming'
})

export const deleteQuestionsEndpoint = () => ({
  path: 'questions',
  method: 'DELETE'
})

export const createAnswerEndpoint = body => ({
  path: `questions/${body.id}/answers`,
  method: 'POST',
  body,
  type: 'streaming'
})

export const updateAnswerEndpoint = body => ({
  path: `questions/${body.question_id}/answers/${body.id}}`,
  method: 'PUT',
  body
})

export const createReportEndpoint = body => ({
  path: 'reports',
  method: 'POST',
  body,
  type: 'streaming'
})

export const promptEndpoint = body => ({
  path: 'prompt',
  method: 'POST',
  type: 'streaming',
  body
})

/* Make a Sphinx Request */
export const makeSphinxRequest = async ({ type, path, method, body }) => {
  const environment = process.env.REACT_APP_SPHINX_ENV
  const authToken = Cookies.getCookie(process.env.REACT_APP_AUTH_TOKEN_COOKIE)
  const endpoint =
    environment === 'local'
      ? SPHINX_LOCALHOST
      : SPHINX_ENDPOINTS[environment]?.[type || 'http']

  if (!endpoint) {
    // eslint-disable-next-line no-console
    console.error('No endpoint for chat in this environment.')
    return undefined
  }

  return fetch(`${endpoint}/${path}`, {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json'
    }
  }).then(response => {
    if (type === 'streaming') {
      return response.body.getReader()
    }

    return response.json()
  })
}

const handleHeaders = (headers, featureLimitKey) => {
  if (!featureLimitKey || !Object.keys(headers).length) return

  const featureLimits = extractLimitHeaders({
    headers: {
      get: key => headers?.[key] || headers?.[key.toLowerCase()]
    }
  })
  if (featureLimitKey && featureLimits) {
    window.dispatchEvent(
      new CustomEvent(SET_FEATURE_LIMIT_EVENT, {
        detail: { featureLimitKey, featureLimits }
      })
    )
  }
}

/* Make a streaming request */
export const makeSphinxStreamingRequest = async (options, callback) => {
  const reader = await makeSphinxRequest(options.request)

  let text = ''
  const readAndAppend = async () => {
    const { done, value } = await reader.read()

    if (done) {
      callback?.({ text, done: true })
      return text
    }

    const streamValues = new TextDecoder()
      .decode(value)
      ?.trim()
      ?.split('/end/')
    streamValues.forEach(streamValue => {
      if (!streamValue?.length) return

      let json
      try {
        json = JSON.parse(streamValue) || {}

        if (json.headers) {
          handleHeaders(json.headers, options?.featureLimitKey)
        } else if (json.error) {
          callback?.({ error: json.error })
        } else if (json.token) {
          text += json.token
          callback?.({ text })
        }

        options?.onStream?.(json)
      } catch {
        /* ignore */
      }
    })

    return readAndAppend()
  }

  return readAndAppend()
}
